<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    scrollable
    @click:outside="close()"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card id="app-policy-management">
      <v-card-title class="text-h5 white d-block pa-5 px-12">
        Select to Unlink
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-9 px-9 font-weight-bold">
        <v-row>
          <v-col cols="2">
            <span class="green--text">
              Name: &nbsp;
            </span>
          </v-col>
          <v-col cols="4">
            {{ item._name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <span class="green--text">
              Surname: &nbsp;
            </span>
          </v-col>
          <v-col cols="4">
            {{ item.surname }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <span class="green--text">
              Email: &nbsp;
            </span>
          </v-col>
          <v-col cols="4">
            {{ item.email }}
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-divider />
      <v-card-text class="mt-12">
        <v-data-table
          v-model="itemToUnlink"
          :headers="headersManagePolicyAppUser"
          :items="full"
          class="elevation-0"
          :single-select="false"
          item-key="policy_id"
          show-select
        />
      </v-card-text>

      <v-divider />
      <v-card-actions class="ma-2 text-center">
        <v-btn
          color="primary"
          class="text-right ml-10"
          @click="close()"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-right ml-10"
          :disabled="checkUnlink()"
          @click="unlink()"
        >
          Unlink
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    full: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      itemToUnlink: [],
      headersManagePolicyAppUser: [
        {
          text: 'Application',
          align: 'start',
          sortable: false,
          value: 'service_name'
        },
        {
          text: 'Policy',
          align: 'start',
          sortable: false,
          value: 'policy_name'
        }
      ]
    };
  },
  computed: {
    ...mapState(['app_user']),
    propModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    ...mapActions(['unlinkUserPolicyAction', 'setSelectPolicies', 'storeAppUsers', 'storeLinkedServices']),
    async unlink() {
      this.overlay = true;
      const data = [
        ...this.itemToUnlink.map(el => {
          return {
            service_id: el.service_id,
            app_user_id: el.app_user_id,
            policy_id: el.policy_id
          };
        })
      ];
      await this.unlinkUserPolicyAction(data);
      await this.storeAppUsers(this.app_user.crm_id);
      await this.setSelectPolicies();
      await this.storeLinkedServices(this.app_user.crm_id);
      this.itemToUnlink = [];
      this.$emit('closeDialog');
      this.$emit('highlight-default-service-column');
      this.overlay = false;
    },
    close() {
      this.$emit('closeDialog');
    },
    checkUnlink() {
      return !this.itemToUnlink[0];
    }
  }
};
</script>
